<template>
  <div id="demo-mode">
    <div class="fixed-header1" v-if="TestMode">
      <h1 class="fixed-header-txt1">
          <v-icon color="#ffffff"> mdi-information </v-icon> YOU'RE IN TEST MODE &nbsp;{{ Today }}  <digital-clock :blink="true" :twelveHour="true" />
      </h1>
    </div>
    <div class="fixed-header2" v-else>
      <h1 class="fixed-header-txt2">
        <v-icon color="#ffffff"> mdi-hands-pray </v-icon> Welcome to ASG Bio Medical Support &nbsp;{{ Today }}  <digital-clock :blink="true" :twelveHour="true" />
      </h1>
    </div>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import DigitalClock from "vue-digital-clock";

export default {
  components:{
    DigitalClock
  },
  data() {
    return {
      TestMode: companyConfig.TestMode,
      Today:new Date().toDateString()
    };
  },
};
</script>

<style lang="scss">
#demo-mode {
  .fixed-header1 {
    width: 100%;
    position: fixed;
    color: #ffffff;
    background-color: #F58A1F;
    top: 0;
    z-index: 99;
    height: 25px;
    text-align: center;
  }
  .fixed-header-txt1 {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }
  .fixed-header2 {
    width: 100%;
    position: fixed;
    color: #ffffff;
    background-color: #034EA1;
    top: 0;
    z-index: 99;
    height: 25px;
    text-align: center;
  }
  .fixed-header-txt2 {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>